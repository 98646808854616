<template>
  <ModalConfirm
    id="modal-approve-claimable"
    ref="modal-approve-claimable"
    message="Saldo user akan bertambah ketika kamu merubah status ke <span class='font-bold'>Disetujui</span>"
    title="Setujui"
    label-confirm-button="Ubah Status Paket"
    label-cancel-button="Kembali"
    :loading-confirm-button="isLoading"
    @on-click-cancel-button="$bvModal.hide('modal-approve-claimable')"
    @on-click-confirm-button="approveClaimLost"
  />
</template>

<script>
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'
import { newAxiosIns } from '@/libs/axios'

export default {
  components: { ModalConfirm },
  // eslint-disable-next-line vue/require-default-prop
  props: { id: Number, ticketId: Number, statusClaim: Number },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    approveClaimLost() {
      this.isLoading = true

      const body = {
        id: this.id,
        ticket_id: this.ticketId,
        status_claim: 1,
      }

      const url = '/komship/api/v1/ticket-admin/handling-claim-lost'
      this.$bvModal.hide('modal-approve-claimable')

      newAxiosIns
        .post(url, body)
        .then(() => {
          this.$toast_success({ message: 'Berhasil menyetujui claim paket hilang' })
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal menyetujui claim paket hilang' })
        })
        .finally(() => {
          this.isLoading = false
          this.$emit('refetch-data')
          this.$bvModal.hide('modal-approve-claimable')
        })
    },
  },
}
</script>

<template>
  <BModal
    id="modalReject"
    ref="modalReject"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    class="modalReject"
    :centered="true"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        width="100"
        class="mb-[24px] mx-auto"
      >
      <h4 class="text-[24px] text-[#333333] font-[600] mb-[12px]">
        Tolak
      </h4>
      <p class="text-[#626262] text-[16px]">
        Kamu yakin ingin menolak pengajuan claim ini?
      </p>
      <p class="text-[14px] text-start mb-[4px]">
        Catatan
      </p>
      <ValidationObserver #default="{ invalid }">
        <BForm @submit.prevent="handleReject">
          <ValidationProvider
            rules="required"
            :custom-messages="messageError"
          >
            <BFormTextarea
              v-model="notes"
              placeholder="Tulis alasan kamu menolak claim retur"
              rows="8"
              max-rows="10"
              class="mb-2 notesReject"
              size="lg"
            />
          </ValidationProvider>
          <div class="flex justify-center">
            <BButton
              size="md"
              variant="outline-primary"
              class="mr-1"
              style="width: 160.5px"
              @click="closeModal"
            >
              Batal
            </BButton>
            <BButton
              size="md"
              variant="primary"
              style="width: 160.5px"
              :disabled="invalid || isLoading"
              type="submit"
              block
            >
              <BSpinner
                v-if="isLoading"
                small
              />
              Tolak
            </BButton>
          </div>
        </BForm>
      </ValidationObserver>
    </div>
  </BModal>
</template>

<script>
import {
  BModal, BButton, BFormTextarea, BForm,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'

export default {
  components: {
    BModal, BButton, BFormTextarea, ValidationObserver, ValidationProvider, BForm,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    ticketId: {
      type: Number,
      default: required,
    },
    getData: {
      type: Function,
      required: true,
    },
    claimType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      notes: '',
      centered: true,
      isLoading: false,
      messageError: {
        required: 'Tulis alasan kamu menolak claim retur',
      },
      required,
      error: '',
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modalReject')
      this.notes = ''
    },
    handleReject() {
      if (this.claimType !== 'hilang') {
        this.handleRejectRetur()
      } else {
        this.handleRejectLost()
      }
    },
    handleRejectLost() {
      this.isLoading = true
      const body = {
        id: this.id,
        ticket_id: this.ticketId,
        status_claim: 2,
        notes: this.notes,
      }

      const url = '/komship/api/v1/ticket-admin/handling-claim-lost'

      newAxiosIns
        .post(url, body)
        .then(() => {
          this.$toast_success({ message: 'Berhasil menolak claim paket hilang' })
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal menolak claim paket hilang' })
        })
        .finally(() => {
          this.$bvModal.hide('modalReject')
          this.getData()
          this.isLoading = false
        })
    },
    async handleRejectRetur() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('status_claim', 2)
      formData.append('notes', this.notes)
      const url = `/v1/ticket-admin/handling-claim-retur/${this.ticketId}`
      await komshipAxiosIns
        .post(url, formData)
        .then(() => {
          this.isLoading = false
          this.$toast_success({ message: 'Berhasil menolak claim retur' })
          this.getData()
          this.$bvModal.hide('modalReject')
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal menolak claim retur' })
          this.$bvModal.hide('modalReject')
          this.getData()
          this.isLoading = false
        })
    },
  },
}
</script>
<style scoped>
.notesReject {
  height: 336px !important;
}
</style>
